<template>
  <el-tabs type="border-card">
    <el-tab-pane label="投稿详情">
      <submission_detail :data_params="data_params"></submission_detail>
    </el-tab-pane>
    <el-tab-pane label="评论">
      <comment_list :data_params="comment_param" view_type="view"></comment_list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import submission_detail from "./submission_detail";
import comment_list from "./comment_list";
export default {
  name: "view_submission",
  components: {
    submission_detail,
    comment_list
  },
  data() {
    return {
      loading: false,
      data_params: {},
      comment_param: {},
    }
  },
  async mounted() {
    this.loading = true;
    let _id = this.$route.query._id;
    let act_id = this.$route.query.act_id;
    this.data_params = {
      _id,
      act_id
    };

    this.comment_param = {
      act_id: this.data_params.act_id,
      submission_id: this.data_params._id,
      type: "parent"
    }

    this.loading = false;
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
